import React from "react"

import ContactAndAddressTile from "../../components/contact-and-address-tile/contact-and-address-tile"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import AnmeldungCtaButton from "../../components/anmeldung-cta-button/anmeldung-cta-button"
import ClassOverview from "../../components/class-overview/class-overview"
import ClassOverviewItem from "../../components/class-overview/class-overview-item/class-overview-item"
import AnmeldungSection from "../../components/anmeldung-section/anmeldung-section"
import SolidHero from "../../components/solid-hero/solid-hero"

const KlasseBEPage = ({ data }) => (
  <Layout>
    <SEO title="Klasse BE"
         description={"Deine Anhängerlizenz muss keine Last sein. Der Klasse BE-Führerschein – jetzt bei deiner Fahrschule Rauscher in Backnang ganz einfach anmelden und einsteigen!"}/>
    <SolidHero imageFluid={data.file.childImageSharp.fluid} imageAlt={"Klasse BE"} primary title={"Autoführerschein"}
               subtitle={() => <>Klasse BE</>}></SolidHero>
    <AnmeldungCtaButton></AnmeldungCtaButton>
    <ClassOverview multiColumn
                   text={"Richtig was anhängen bedeutet vor allem auch, andere abzuhängen. Zum Beispiel in Sachen Flexibilität, beim immens größeren Stauraum oder auch in Puncto Manövrierfähigkeiten. Gerade bei Letzteren macht dir nach erfolgreich bestandener Prüfung niemand so schnell was vor. Mit dem BE-Führerschein holst du dir also eine echt praktische Erweiterung mit vielerlei Vorteilen in den Geldbeutel. Nebenbei darfst du natürlich alles, was du mit der reinen Klasse B auch schon darfst. Nur eben mit mehr Gewicht (die zulässige Gesamtmasse liegt hier bei sieben Tonnen) und ohne, dass eine zusätzliche Theorieprüfung anfällt. Den BE-Führerschein gibt’s, anders als den BF17, allerdings erst ab 18 Jahren. Zwingende Voraussetzung für die BE-Erweiterung ist in jedem Fall eine gültige B-Lizenz, wobei sich beide Führerscheine auch hervorragend kombinieren lassen. Wenn‘s dir lediglich um den „E“-Zusatz geht, fällt übrigens keine zusätzliche Theorieprüfung an. Dann erwarten dich nur ein paar zusätzliche Übungsstunden und gesetzliche Sonderfahrten vor der Prüfung – und wenn das alles passt, kannst du in Zukunft (fast) jede Menge mitnehmen!"}>
      <ClassOverviewItem title={"Ausbildung"}>
        <li>Ausbildung: Praxis</li>
        <li>Prüfung: praktische Prüfung</li>
        <li>Vorbesitz: Klasse B</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Stundenpensum"}>
        <li>Übungsstunden: nach Bedarf</li>
        <li>Überlandfahrt: 3</li>
        <li>Autobahnfahrt: 1</li>
        <li>Dunkelfahrt: 1</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Vorraussetzungen"}>
        <li>Mindestalter: 18 Jahre, 17 Jahre beim Begleiteten Fahren (BF17)</li>
        <li>Führerschein der Klasse B</li>
        <li>Biometrisches Passfoto</li>
        <li>Sehtest</li>
        <li>Erste-Hilfe-Kurs</li>
        <li>Personalausweis oder Reisepass mit Meldebestätigung</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Anhängerregelung"}>
        <li>Anhänger mit zG ab 750kg, max 3.500 kg</li>
      </ClassOverviewItem>
      <ClassOverviewItem title={"Weitere Infos"} linkPath={"https://tuvsud.com"} linkText={"Zur Website des TÜV"}>
        <li>Weitere Infos zu allen Führerscheinklassen findest du auf der Website des TÜV.</li>
      </ClassOverviewItem>

    </ClassOverview>
    <AnmeldungSection></AnmeldungSection>
    <ContactAndAddressTile></ContactAndAddressTile>
  </Layout>
)

export default KlasseBEPage
export const query = graphql`
  query KlasseBEPageQuery {
    file(relativePath: { eq: "anhaenger.png" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
